<template>
  <v-app>
    <app-header></app-header>
    <div class="page-wrapper">
      <router-view></router-view>
    </div>
    <v-snackbar v-model="errorSnackbar" multi-line outlined color="error" rounded>
      {{ errorText }}
    </v-snackbar>
  </v-app>
</template>

<script>
import AppHeader from './components/AppHeader.vue';
import { api } from '/src/plugins/axios';

export default {
  name: 'App',
  components: {
    AppHeader
  },
  data() {
    return {
      errorText: null,
      errorSnackbar: false
    }
  },
  created() {
    const self = this;
    api.interceptors.response.use(undefined, function(err) {
      self.errorSnackbar = true;
      try {
        const resData = err.response.data;
        self.errorText = resData.statusCode === 500
          ? 'Something went wrong'
          : resData.message.map(m => m[0].toUpperCase() + m.slice(1)).join('\n')
      } catch {
        self.error = 'Something went wrong'
      }

      return Promise.reject(err);
    })
  }
};
</script>

<style lang="scss">
@import "./styles/index.scss";

.page-wrapper {
  display: flex;
  padding: 40px 0;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
