<template>
  <div class="buy-form">
    <v-alert
        v-if="showErrorAlert"
        outlined
        type="error"
    >
      <template slot="prepend">
        <img class="mr-3" src="@/assets/images/alert-error-icon.svg" alt="">
      </template>
      Payment error!
    </v-alert>
    <v-alert
        v-if="showSuccessAlert"
        outlined
        type="success"
    >
      <template slot="prepend">
        <img class="mr-3" src="@/assets/images/alert-success-icon.svg" alt="">
      </template>
      Payment was successful
    </v-alert>
    <v-form ref="form" class="form-card">
      <h1 class="buy-form-card-title">Buy cryptocurrency</h1>
      <div>
        <p>Payment method</p>
        <v-radio-group class="radio-btn-wrapper" v-model="form.paymentMethod">
          <v-radio value="adv">
            <template v-slot:label>
              <div class="radio-btn-content">
                AdvCash wallet
                <info-tooltip>
                  Pay with your funds from your AdvCash account.
                </info-tooltip>
              </div>
            </template>
          </v-radio>
          <v-radio value="card">
            <template v-slot:label>
              <div class="radio-btn-content">
                Bank card
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </div>
      <div class="select-section">
        <div>
          <p>Buy</p>
          <v-select
              :items="items"
              :item-value="item => item.id"
              return-object
              v-model="form.buyCurrency"
              dense
              solo
              flat
              :menu-props="{contentClass: 'select-menu'}"
          >
            <template slot="selection" slot-scope="data">
              <img :src="require(`@/assets/images/${data.item.icon}`)" alt="">
              {{ data.item.name }}
            </template>
            <template slot="item" slot-scope="data">
              <img :src="require(`@/assets/images/${data.item.icon}`)" alt="">
              {{ data.item.name }}
            </template>
            <template slot="append">
              <img src="@/assets/images/select-arrow.svg" alt="">
            </template>
          </v-select>
        </div>
        <div>
          <p>Payment currency</p>
          <v-text-field :error-messages="amountError"
                        @focus="focusAmount"
                        single-line
                        v-model="form.paymentAmount"
                        type="number"
                        class="field-with-select right-bordered fw-600"
                        solo flat dense>
            <template v-slot:append>
              <v-select
                  :items="currencyItems"
                  :item-value="item => item.name"
                  v-model="form.paymentCurrency"
                  dense
                  hide-details
                  flat
                  :menu-props="{contentClass: 'select-menu currency-menu'}"
              >
                <template slot="selection" slot-scope="data">
                  <span class="currency-sign">{{ data.item.sign }}</span>
                  {{ data.item.name }}
                </template>
                <template slot="item" slot-scope="data">
                  <span class="currency-sign">{{ data.item.sign }}</span>
                  {{ data.item.name }}
                </template>
                <template slot="append">
                  <img src="@/assets/images/select-arrow.svg" alt="">
                </template>
              </v-select>
            </template>
          </v-text-field>
        </div>
      </div>
      <div>
        <p>Enter your wallet number</p>
        <v-text-field solo flat dense :error-messages="addressError"
                      v-model="form.wallet">
          <template slot="append">
            <info-tooltip type="info" :width="400">
              Please ensure that the wallet address is correct! Please ensure
              that the wallet address supports the Binance Smart Chain network.
              You will lose your assets if you enter the wrong address or if
              you use a different network.
            </info-tooltip>
          </template>
        </v-text-field>
      </div>
      <div>
        <p class="md-none">I will get ≈</p>
        <div class="total-value-row">
          <span class="total-value-row-hint">I will get ≈</span>
          <div class="total-value-row-right">
            <img :src="require(`@/assets/images/${form.buyCurrency.icon}`)" alt="">
            <span class="total-value-row-text fw-600 ml-2">{{ crypto }} {{ form.buyCurrency.name }}</span>
            <v-text-field class="hidden-input ma-0" dense hide-details>
              <template slot="append">
                <info-tooltip :width="400">
                  Your bank may charge additional fees. Check the commission before
                  sending money. After a successful payment crypto will be credited
                  to your wallet within a few minutes.
                </info-tooltip>
              </template>
            </v-text-field>
          </div>
        </div>
      </div>
      <div>
        <form ref="acForm" method="POST" action="https://wallet.advcash.com/sci/">
          <input type="hidden" name="ac_account_email">
          <input type="hidden" name="ac_sci_name">
          <input type="hidden" name="ac_amount">
          <input type="hidden" name="ac_currency">
          <input type="hidden" name="ac_order_id">
          <input type="hidden" name="ac_sign">
          <input type="hidden" name="ac_ps">
          <input type="hidden" name="ac_comments" value="Payment">
          <v-btn :disabled="submitBtnDisabled" @click="getFormAndSubmit" type="submit" class="submit-btn" depressed>
            Buy
          </v-btn>
        </form>
      </div>
    </v-form>
  </div>

</template>

<script>
import InfoTooltip from '/src/components/InfoTooltip.vue';
import { api } from '/src/plugins/axios';
import { cryptoItems, currencyItems } from '/src/components/forms/shared';

export default {
  name: 'BuyForm',
  components: {
    InfoTooltip
  },
  data() {
    return {
      form: {
        paymentMethod: 'card',
        paymentAmount: 0,
        paymentCurrency: currencyItems[0].name,
        buyCurrency: cryptoItems[0],
        wallet: ''
      },
      paymentAmountDebounceTimeoutId: null,
      crypto: 0,
      amountError: null,
      addressError: null,
      acForm: {},
      currencyItems: currencyItems,
      items: cryptoItems,
      showSuccessAlert: false,
      showErrorAlert: false,
      isProcessing: false
    };
  },
  computed: {
    submitBtnDisabled() {
      return !this.crypto || !this.form.wallet || this.isProcessing || !!this.addressError;
    }
  },
  methods: {
    focusAmount() {
      if (this.form.paymentAmount === 0) {
        this.form.paymentAmount = null;
      }
    },
    getFormAndSubmit(e) {
      e.preventDefault();
      api.post('change', {
        "token": this.form.buyCurrency.name,
        "fiat": this.form.paymentCurrency,
        "count": this.form.paymentAmount,
        "wallet": this.form.wallet,
        "type_pay": this.form.paymentMethod
      }).then(({data}) => {
        this.$refs.acForm.ac_account_email.value = data.form.ac_account_email;
        this.$refs.acForm.ac_sci_name.value = data.form.ac_sci_name;
        this.$refs.acForm.ac_amount.value = data.form.ac_amount;
        this.$refs.acForm.ac_currency.value = data.form.ac_currency;
        this.$refs.acForm.ac_order_id.value = data.form.ac_order_id;
        this.$refs.acForm.ac_sign.value = data.form.ac_sign;
        this.$refs.acForm.ac_ps.value = data.form.ac_ps;
        this.$refs.acForm.submit();
      });
    }
  },
  watch: {
    form: {
      handler(val) {
        if (!val.paymentAmount) return;
        if (this.paymentAmountDebounceTimeoutId !== null) {
          clearTimeout(this.paymentAmountDebounceTimeoutId);
        }
        console.log(val);

        this.paymentAmountDebounceTimeoutId = setTimeout(() => {
          this.paymentAmountDebounceTimeoutId = null;
          this.isProcessing = true;
          api.post('info', {
            "token": val.buyCurrency.name,
            "fiat": val.paymentCurrency,
            "count": val.paymentAmount,
            "wallet": val.wallet,
            "type_pay": val.paymentMethod
          }).then(({data}) => {
            this.isProcessing = false;
            if (data.error_count) {
              this.amountError = data.error_count.currency;
              this.crypto = 0;
              return;
            }
            if (data.error_address) {
              this.addressError = data.error_address.currency;
              return;
            }
            this.amountError = null;
            this.addressError = null;
            this.crypto = data.crypto;
          });
        }, 400);
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.form-card {
  --from-card-width: 615px;
}

.radio-btn-wrapper {
  margin: 0;
  padding: 0;

  .v-input--selection-controls__input .v-input--selection-controls__ripple {
    display: none;
  }

  .v-icon.mdi-radiobox-marked {
    border: 1px solid $grey-default;
    border-radius: 50%;

    &:before {
      transition: .1s;
      content: "";
      border-radius: 50%;
      height: 12px;
      width: 12px;
      background-color: $white;
      border: 5px solid $primary;
      box-sizing: content-box;
    }
  }

  .v-icon.mdi-radiobox-blank {
    border: 1px solid $grey-default;
    border-radius: 50%;

    &:before {
      content: ""
    }
  }

  ::v-deep .v-input--radio-group__input {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr;
    @media screen and (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }

    .v-radio {
      padding: 14px;
      border: 1px solid $grey-default;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: .3s;

      &:first-child {
        margin-bottom: 0;
      }

      &.v-item--active {
        background-color: $grey-default;

        .v-label {
          color: $primary;
        }
      }

      .radio-btn-content {
        width: 100%;
        font-size: 14px;
        padding-left: 8px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
</style>
