export const currencyItems = [
  { name: 'USD', sign: '$'},
  { name: 'EUR', sign: '€'},
  { name: 'RUB', sign: '₽'},
  { name: 'UAH', sign: '₴'},
  { name: 'GBP', sign: '£'},
  { name: 'KZT', sign: '₸'},
  { name: 'BRL', sign: 'R$'},
  { name: 'TRY', sign: '₺'}
];

export const cryptoItems = [
  {
    id: 1,
    name: 'BNB',
    icon: 'BNB.svg'
  },
  {
    id: 2,
    name: 'BUSD',
    icon: 'BUSD.svg'
  },
  {
    id: 3,
    name: 'STM',
    icon: 'STM.svg'
  },
  {
    id: 4,
    name: 'USDT',
    icon: 'tether.png'
  },
];
