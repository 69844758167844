import Vue from 'vue';
import { email, required } from 'vee-validate/dist/rules';
import { ValidationProvider, extend, setInteractionMode, ValidationObserver } from 'vee-validate';

setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} is required',
})

extend('email', {
  ...email,
  message: '{_field_} is not valid',
})

extend('cardNumber', {
  validate: v => Vue.prototype.$cardFormat.validateCardNumber(v),
  message: '{_field_} is not valid',
})

extend('cardExpiry', {
  validate: v => Vue.prototype.$cardFormat.validateCardExpiry(v),
  message: 'Invalid expiration date',
})

extend('phoneNumber', {
  validate: (_, [isValid]) => isValid === null ? true : isValid === 'true',
  message: 'Phone number is not valid'
})

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
