<template>
  <header class="header">
    <div class="header-content">
      <a class="logo-link" href="https://streamity.org/">
        <img class="header-content-logo" src="@/assets/images/logo.svg" alt="logo">
      </a>
      <div class="header-content-links">
        <a class="link-item" href="https://app.streamity.org/#/swap">Swap</a>
        <router-link class="link-item" to="/buy">Buy</router-link>
        <router-link class="link-item" to="/sell">Sell</router-link>
        <a class="link-item" href="https://app.streamity.org/#/pool">Pool</a>
        <a class="link-item" href="https://app.streamity.org/#/earn">Earn</a>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'AppHeader'
};
</script>

<style lang="scss" scoped>
.header {
  font-family: $body-font-family, sans-serif;
  padding: 16px;

  &-content {
    display: flex;
    margin-right: 12px;
    width: fit-content;

    .logo-link {
      margin-right: 12px;
      display: flex;
    }

    &-logo {
      width: 90%;
    }

    &-links {
      box-sizing: border-box;
      margin: 0;
      min-width: 0;
      width: 100%;
      display: flex;
      padding: 0;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
    }

    .link-item {
      display: flex;
      flex-flow: row nowrap;
      border-radius: 3rem;
      outline: none;
      cursor: pointer;
      text-decoration: none;
      color: $white;
      font-size: 1rem;
      width: fit-content;
      margin: 0 12px;
      font-weight: 500;
    }

    .link-item.router-link-active {
      font-weight: 600;
    }
  }
}
</style>
