import Vue from 'vue'
import VueRouter from 'vue-router'
import BuyForm from "/src/components/forms/BuyForm";
import SellForm from "/src/components/forms/SellForm";

Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {path: '/', redirect: '/buy'},
    {path: '/buy', component: BuyForm},
    {path: '/sell', component: SellForm}
  ]
})
